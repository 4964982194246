import { createApp } from 'vue'
import VueRouter from "vue-router"


import App from './App.vue'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faTachometerAlt, faFileAlt, faClipboardCheck, faComments, faLifeRing, faChartPie, faInfoCircle, faBars, faFileMedical, faExpandArrowsAlt, faBell, faClock, faHeading, faCertificate, faGraduationCap, faUser, faUndoAlt, faRedoAlt, faInfo, faExclamationTriangle, faStar, faSyncAlt, faChevronUp, faPencilAlt, faSave, faCamera, faPlus, faTicketAlt, faTasks, faExternalLinkAlt, faArchive } from '@fortawesome/free-solid-svg-icons'
import { faFacebookSquare, faGoogle } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faTachometerAlt, faFileAlt, faClipboardCheck, faComments, faLifeRing, faChartPie, faInfoCircle, faBars, faFileMedical, faExpandArrowsAlt, faBell, faClock, faHeading, faCertificate, faGraduationCap, faUser, faUndoAlt, faRedoAlt, faInfo, faExclamationTriangle, faStar, faSyncAlt, faChevronUp, faFacebookSquare, faGoogle, faPencilAlt, faSave, faCamera, faPlus, faTicketAlt, faTasks, faExternalLinkAlt, faArchive )

require("admin-lte");
import "admin-lte/plugins/select2/css/select2.min.css";
import "admin-lte/dist/css/adminlte.min.css";
import "admin-lte/dist/js/adminlte.min.js";
import "admin-lte/plugins/bootstrap/js/bootstrap.min.js";


import router from "./router/index";

createApp(App)
.component('font-awesome-icon', FontAwesomeIcon)
.use(router)
.use(VueRouter)
.mount('#app')
