import { createRouter, createWebHistory } from 'vue-router';

const routes = [
    {
        path: '/',
        name: '',
        redirect: 'dashboard',
        component: () => import('../containers/Logged.vue'),
        children: [
            {
                path: 'dashboard',
                name: 'dashboard',
                meta: { title: 'Dashboard | Redação Online' },
                component: () => import('../components/Index.vue')
            },
            {
                path: '/perfil',
                name: 'profile',
                meta: { title: 'Perfil | Redação Online' },
                component: () => import('../components/Profile.vue')
            },
            {
                path: '/corrigir',
                name: 'correct.index',
                meta: { title: 'Redações | Redação Online' },
                component: () => import('../components/correct/Index.vue')
            },
            {
                path: '/corrigir/:id',
                name: 'correct.show',
                meta: { title: 'Corrigir | Redação Online' },
                component: () => import('../components/correct/Show.vue')
            },
            {
                path: '/corrigida',
                name: 'corrected.index',
                component: () => import('../components/corrected/Index.vue')
            },
            {
                path: '/chat',
                name: 'chat.index',
                meta: { title: 'Chat | Redação Online' },
                component: () => import('../components/chat/Index.vue') 
            },
            {
                path: '/chat/:id',
                name: 'chat.show',
                meta: { title: 'Chat | Redação Online' },
                component: () => import('../components/chat/Show.vue')
            },
            {
                path: '/suporte',
                name: 'support.index',
                component: () => import('../components/support/Index.vue'),
            },
            {
                path: '/suporte/novo',
                name: 'support.create',
                meta: { title: 'Novo suporte | Redação Online' },
                component: () => import('../components/support/Create.vue'),
            },
            {
                path: '/suporte/:id',
                name: 'support.show',
                component: () => import('../components/support/Show.vue'),
            },
            {
                path: '/relatorios/corrigidas',
                name: 'reports.corrected',
                component: () => import('../components/reports/corrected/Index.vue')
            },
            { 
                path: '/acompanhamentos',
                name: 'accompaniment.index',
                component: () => import('../components/accompaniment/Index.vue')
            },
            { 
                path: '/editais',
                name: 'tests.index',
                component: () => import('../components/tests/Index.vue')
            },
        ]
    },
    {
        path: '/login',
        name: 'login',
        component: () => import('../components/Login.vue'),
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.name !== 'login' && !localStorage.getItem('access_token')) {

        next({ name: 'login' });

    } else if (to.name === 'login' && localStorage.getItem('access_token')) {

        next({ name: 'dashboard' });

    } else {

       next()

    }
})

export default router;