<template>
    <div id="app">
        <router-view />
    </div>
</template>


<script>
    export default {
        name: 'App',
        watch: {
            $route(to) {
                document.title = to.meta.title || process.env.VUE_APP_TITLE
            },
        },
    }
</script>